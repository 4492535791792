import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'
import store from '@/store/store'
import router from '@/router'

const axios_instance = axios.create({
  baseURL:apiBaseUrl,
});

var isRefreshing = false

//Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    store.dispatch('AUTH_INSPECT')
      .catch(() => {
        store.commit('AUTH_LOGOUT')
        router.push('/pages/login')
      })
    return response
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && originalRequest.url === apiBaseUrl +'/auth/token/refresh') {
     router.push('/pages/login');
     return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true
        originalRequest._retry = true
        const refreshToken = JSON.parse(store.state.auth.token).refresh

        return axios_instance.post(apiBaseUrl +'/auth/token/refresh', {"refresh": refreshToken})
         .then(res => {
           if (res.status === 200) {
             store.commit('AUTH_REFRESH', res.data)
             originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access
             return axios_instance(originalRequest)
           }
           else {
             store.commit('AUTH_LOGOUT')
             router.push('/pages/login')
             return Promise.reject(error)
           }
         })
         .catch(() => {
           store.commit('AUTH_LOGOUT')
           router.push('/pages/login')
           return Promise.reject(error)
         })
         .finally(() => {
           isRefreshing = false
         })
     }
     originalRequest.headers['Authorization'] = 'Bearer ' + JSON.parse(store.state.auth.token).access
     return axios_instance(originalRequest)
   }
   return Promise.reject(error)
});

export class APIManagerConnected {

  // ------------------------ CLIENTS ------------------
  getAllInactiveClients (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
      The url is all-inactive-clients/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  createClientAccess (token, client_pk, username, email, first_name, last_name,
    password, confirm_password) {
    const url = apiBaseUrl + '/create-client-access'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'client': client_pk,
      'username': username,
      'email': email,
      'first_name': first_name,
      'last_name': last_name,
      'password': password,
      'confirm_password': confirm_password,
    }

    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyClientAccess (token, client_pk, username, email, first_name, last_name) {
    const url = apiBaseUrl + `/client-access-profile/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'client': client_pk,
      'username': username,
      'email': email,
      'first_name': first_name,
      'last_name': last_name,
    }

    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyClientAccessPassword (token, client_pk, new_password, new_password_confirmed) {
    const url = apiBaseUrl + '/modify-client-access-password/' + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'new_password': new_password,
      'new_password_confirmed': new_password_confirmed,
    }

    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  deleteClientAccess (token, client_pk) {
    const url = apiBaseUrl + `/client-access-profile/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }

    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  // ------------------------ CONTACTS ------------------
  getAllInactiveContacts (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
      The url is all-inactive-contacts/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  // ------------------------ MISSIONS -----------------
  getAllMissions (token) {
    const url = apiBaseUrl + '/get-all-missions'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllMissionsPaginator (token, url) {

    /* Be careful in this page because a URL with the page is needed !!! */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  searchMissionByName (token, search) {
    const url = apiBaseUrl + `/search-missions/` + search
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllInactiveMissions (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
      The url is all-inactive-missions/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addMission (token, contact_pk, client_pk, referent_pk, name, types,
    is_billed, is_honoraires, is_honoraires_resultats, is_regie_plafonnee, is_forfait, is_aj, montant_ht, resultats_percentage,
    starting_date, ending_date, rg_number, description, contre_client_is_firm, contre_client_siren, contre_client_name, contre_client_first_name,
    contre_client_last_name, contre_avocat_name, contre_avocat_email, contre_avocat_phone_country_code, contre_avocat_phone_number) {
    const url = apiBaseUrl + '/create-mission'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'client': client_pk,
      'referent': referent_pk,
      'name': name,
      'types': types,
      'is_billed': is_billed,
      'is_honoraires': is_honoraires,
      'is_honoraires_resultats': is_honoraires_resultats,
      'is_regie_plafonnee': is_regie_plafonnee,
      'is_forfait': is_forfait,
      'is_aj': is_aj,
      'montant_ht': montant_ht,
      'starting_date': starting_date,
      'rg_number': rg_number,
      'description': description,
      'contre_client_is_firm': contre_client_is_firm,
      'contre_client_siren': contre_client_siren,
      'contre_client_name': contre_client_name,
      'contre_client_first_name': contre_client_first_name,
      'contre_client_last_name': contre_client_last_name,
      'contre_avocat_name': contre_avocat_name,
      'contre_avocat_email': contre_avocat_email,
    }
    if (contact_pk) {
      data['contact'] = contact_pk
    }

    if (ending_date) {
      data['ending_date'] = ending_date
    }
    else {
      data['ending_date'] = null
    }

    if (resultats_percentage) {
      data['resultats_percentage'] = resultats_percentage
    }
    else {
      data['resultats_percentage'] = null
    }

    if (contre_avocat_phone_number) {
      data['contre_avocat_phone_country_code'] = contre_avocat_phone_country_code
      data['contre_avocat_phone_number'] = contre_avocat_phone_number
    }
    else {
      data['contre_avocat_phone_country_code'] = ''
      data['contre_avocat_phone_number'] = ''
    }

    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyMission (token, mission_pk, contact_pk, client_pk, referent_pk, name, types,
    is_billed, is_honoraires, is_honoraires_resultats, is_regie_plafonnee, is_forfait, is_aj,
    montant_ht, resultats_percentage, starting_date, ending_date, rg_number, description, contre_client_is_firm, contre_client_siren,
    contre_client_name, contre_client_first_name, contre_client_last_name, contre_avocat_name, contre_avocat_email,
    contre_avocat_phone_country_code, contre_avocat_phone_number) {
    const url = apiBaseUrl + `/mission/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'client': client_pk,
      'referent': referent_pk,
      'name': name,
      'types': types,
      'is_billed': is_billed,
      'is_honoraires': is_honoraires,
      'is_honoraires_resultats': is_honoraires_resultats,
      'is_regie_plafonnee': is_regie_plafonnee,
      'is_forfait': is_forfait,
      'is_aj': is_aj,
      'montant_ht': montant_ht,
      'starting_date': starting_date,
      'ending_date': ending_date,
      'rg_number': rg_number,
      'description': description,
      'contre_client_is_firm': contre_client_is_firm,
      'contre_client_siren': contre_client_siren,
      'contre_client_name': contre_client_name,
      'contre_client_first_name': contre_client_first_name,
      'contre_client_last_name': contre_client_last_name,
      'contre_avocat_name': contre_avocat_name,
      'contre_avocat_email': contre_avocat_email,
    }
    if (contact_pk) {
      data['contact'] = contact_pk
    }

    if (ending_date) {
      data['ending_date'] = ending_date
    }
    else {
      data['ending_date'] = null
    }

    if (resultats_percentage) {
      data['resultats_percentage'] = resultats_percentage
    }
    else {
      data['resultats_percentage'] = null
    }

    if (contre_avocat_phone_number) {
      data['contre_avocat_phone_country_code'] = contre_avocat_phone_country_code
      data['contre_avocat_phone_number'] = contre_avocat_phone_number
    }
    else {
      data['contre_avocat_phone_country_code'] = ''
      data['contre_avocat_phone_number'] = ''
    }

    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  archiveMission (token, mission_pk) {
    const url = apiBaseUrl + `/mission/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  activateMission (token, mission_pk) {
    const url = apiBaseUrl + `/activate-mission/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'headers': headers,
      }
    )
  }

  addCollaborateurToMission (token, mission_pk, collaborateur_pk) {
    const url = apiBaseUrl + `/add-collaborateur-to-mission/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'collaborateur_pk': collaborateur_pk,
      },
      {
        'headers': headers,
      }
    )
  }

  removeCollaborateurFromMission (token, mission_pk, collaborateur_pk) {
    const url = apiBaseUrl + `/remove-collaborateur-from-mission/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'collaborateur_pk': collaborateur_pk,
      },
      {
        'headers': headers,
      }
    )
  }


  // --------------------- PRIORITIES -------------------

  getAllPriorities (token) {
    const url = apiBaseUrl + '/get-all-priorities'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllMissionPriorities (token, mission_pk) {
    const url = apiBaseUrl + `/mission-all-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  getAllMissionInactivePriorities (token, mission_pk) {
    const url = apiBaseUrl + `/mission-all-inactive-priorities/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  // ------------- B2C CONVENTION ---------------------

  createConventionHonoraires(token, mission_pk) {
    const url = apiBaseUrl + `/create-convention/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(url, {}, {'headers': headers,})
  }

  getConventionHonoraires(token, convention_pk) {
    const url = apiBaseUrl + '/convention/' + convention_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyConventionHonoraires(token, convention) {
    const url = apiBaseUrl + '/convention/' + convention.id
    var data = {
      introduction: convention.introduction,
      preambule: convention.preambule,
      signature: convention.signature,
      footer: convention.footer
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }


  downloadConventionHonorairesAsPdf (token, convention_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-convention/' + convention_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }



  // -------------- CONVENTION ARTICLE PAR DEFAULT -----------
  createConventionHonorairesArticle(token, convention, title) {
    const url = apiBaseUrl + '/create-convention-article/' + convention.id
    var data = {
      title: title,
      body: '<span style="background-color: rgb(255, 255, 0);">Ecrivez le contenu de votre article</span>',
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(url, data, {'headers': headers,})
  }

  modifyConventionHonorairesArticle(token, article) {
    const url = apiBaseUrl + '/convention-article/' + article.id
    var data = {
      title: article.title,
      body: article.body,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  deleteConventionHonorairesArticle(token, article) {
    const url = apiBaseUrl + '/convention-article/' + article.id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }

  ascendConventionHonorairesArticle(token, article_pk) {
    const url = apiBaseUrl + '/ascend-convention-article/' + article_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  descendConventionHonorairesArticle(token, article_pk) {
    const url = apiBaseUrl + '/descend-convention-article/' + article_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }





  // -------------- CONVENTION ANNEXE PAR DEFAULT -----------
  createConventionHonorairesAnnexe(token, convention, title) {
    const url = apiBaseUrl + '/create-convention-annexe/' + convention.id
    var data = {
      title: title,
      body: '<span style="background-color: rgb(255, 255, 0);">Ecrivez le contenu de votre annexe</span>',
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(url, data, {'headers': headers,})
  }

  modifyConventionHonorairesAnnexe(token, annexe) {
    const url = apiBaseUrl + '/convention-annexe/' + annexe.id
    var data = {
      title: annexe.title,
      body: annexe.body,
    }
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, data, {'headers': headers,})
  }

  deleteConventionHonorairesAnnexe(token, annexe) {
    const url = apiBaseUrl + '/convention-annexe/' + annexe.id
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(url, {'headers': headers,})
  }

  ascendConventionHonorairesAnnexe(token, annexe_pk) {
    const url = apiBaseUrl + '/ascend-convention-annexe/' + annexe_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  descendConventionHonorairesAnnexe(token, annexe_pk) {
    const url = apiBaseUrl + '/descend-convention-annexe/' + annexe_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(url, {}, {'headers': headers,})
  }

  // -------------- FACTURES -------------------
  addFacture (token, mission_pk, exercice_comptable, facture_title, facturation_address, delai_paiement,
        date_facturation, date_echeance, is_acompte, is_modalites_virement,
        is_modalites_cb, is_modalites_cash, is_modalites_cheque, message_facture) {
    const url = apiBaseUrl + '/create-facture'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'mission': mission_pk,
        'exercice_comptable': exercice_comptable,
        'facture_title': facture_title,
        'facturation_address': facturation_address,
        'delai_paiement': delai_paiement,
        'date_facturation': date_facturation,
        'date_echeance': date_echeance,
        'is_acompte': is_acompte,
        'is_modalites_virement': is_modalites_virement,
        'is_modalites_cb': is_modalites_cb,
        'is_modalites_cash': is_modalites_cash,
        'is_modalites_cheque': is_modalites_cheque,
        'message_facture': message_facture,
      },
      {
        'headers': headers,
      }
    )
  }

  createFactureFromDevis (token, devis_pk, mission_pk, exercice_comptable, facture_title, facturation_address, delai_paiement,
        date_facturation, date_echeance, is_acompte, is_modalites_virement,
        is_modalites_cb, is_modalites_cash, is_modalites_cheque, message_facture) {
    const url = apiBaseUrl + '/create-facture-from-devis/' + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'mission': mission_pk,
        'exercice_comptable': exercice_comptable,
        'facture_title': facture_title,
        'facturation_address': facturation_address,
        'delai_paiement': delai_paiement,
        'date_facturation': date_facturation,
        'date_echeance': date_echeance,
        'is_acompte': is_acompte,
        'is_modalites_virement': is_modalites_virement,
        'is_modalites_cb': is_modalites_cb,
        'is_modalites_cash': is_modalites_cash,
        'is_modalites_cheque': is_modalites_cheque,
        'message_facture': message_facture,
      },
      {
        'headers': headers,
      }
    )
  }

  duplicateFacture (token, facture_pk) {
    const url = apiBaseUrl + `/duplicate-facture/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url, {}, { 'headers': headers,}
    )
  }

  getFacture(token, pk) {
    const url = apiBaseUrl + `/facture/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyFacture(token, facture_pk, facture_title, facturation_address, delai_paiement,
        date_facturation, date_echeance, is_acompte, is_modalites_virement,
        is_modalites_cb, is_modalites_cash, is_modalites_cheque, message_facture) {

    const url = apiBaseUrl + `/facture/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'facture_title': facture_title,
        'facturation_address': facturation_address,
        'delai_paiement': delai_paiement,
        'date_facturation': date_facturation,
        'date_echeance': date_echeance,
        'is_acompte': is_acompte,
        'is_modalites_virement': is_modalites_virement,
        'is_modalites_cb': is_modalites_cb,
        'is_modalites_cash': is_modalites_cash,
        'is_modalites_cheque': is_modalites_cheque,
        'message_facture': message_facture,
      },
      {
        'headers': headers,
      }
    )
  }
  deleteFacture(token, facture_pk) {
    const url = apiBaseUrl + `/facture/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  markFactureAsSent(token, facture_pk) {
    const url = apiBaseUrl + `/mark-facture-as-sent/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  activateFacture(token, facture_pk) {
    const url = apiBaseUrl + `/activate-facture/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }

  getAllMissionFactures(token, mission_pk) {
    const url = apiBaseUrl + `/get-all-factures/` + mission_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }


  getAllFacturesForManager (token, startDate, endDate, client) {
    /*
    BE CAREFUL : client can be STRING as '' : c'est quand rien n'est sélectionné
    */
    const url = apiBaseUrl + `/factures-manager/` + startDate + '/' + endDate
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {}
    if (client != '') {
      params['client'] = client
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }

  getAllLateFacturesForManager (token) {
    const url = apiBaseUrl + `/all-late-factures`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  getClientAllFactures (token, client_pk) {
    const url = apiBaseUrl + `/get-client-all-factures/` + client_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  getAllInactiveFactures (token, url) {

    /* Be careful in this page because a URL with the page is needed !!!
      The url is all-inactive-factures/
    */

    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  downloadFacture (token, facture_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-facture/' + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  sendFactureToClient (token, facture_pk, sending_email, customized_message, answer_email) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/send-facture-to-client/' + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      sending_email: sending_email,
      customized_message: customized_message,
      answer_email: answer_email
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      },
    )
  }


  // -------------- UNTES FACTURES --------------

  getAllFactureUnitesFactures (token, facture_pk) {
    const url = apiBaseUrl + `/get-all-unite-factures/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addUniteFacture (token, facture_pk, date, description, quantite,
        prix_unitaire, remise_ht, tva) {
    const url = apiBaseUrl + '/create-unite-facture'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'facture': facture_pk,
      'date': date,
      'description': description,
      'quantite': quantite,
      'prix_unitaire': prix_unitaire,
      'tva': tva
    }

    if (remise_ht == '') {
      data['remise_ht'] = 0
    }
    else {
      data['remise_ht'] = remise_ht
    }
    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  addMultipleUnitesFacture (token, unites_factures) {
    const url = apiBaseUrl + '/create-multiple-unites-facture'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      unites_factures,
      {
        'headers': headers,
      }
    )
  }

  modifyUniteFacture (token, unite_facture_pk, date, description, quantite,
        prix_unitaire, remise_ht, tva) {
    const url = apiBaseUrl + `/unite-facture/` + unite_facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'date': date,
        'description': description,
        'quantite': quantite,
        'prix_unitaire': prix_unitaire,
        'remise_ht': remise_ht,
        'tva': tva,
      },
      {
        'headers': headers,
      }
    )
  }

  deleteUniteFacture (token, unite_facture_pk) {
    const url = apiBaseUrl + `/unite-facture/` + unite_facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }



  // -------------- PAIEMENTS -------------------
  getAllFacturePaiements (token, facture_pk) {
    const url = apiBaseUrl + `/get-all-paiements/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addPaiement (token, facture_pk, date, modalites, montant_ttc) {
    const url = apiBaseUrl + '/create-paiement'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'facture': facture_pk,
        'date': date,
        'modalites': modalites,
        'montant_ttc': montant_ttc,
      },
      {
        'headers': headers,
      }
    )
  }

  modifyPaiement(token, paiement_pk, date, modalites, montant_ttc) {
    const url = apiBaseUrl + `/paiement/` + paiement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'date': date,
        'modalites': modalites,
        'montant_ttc': montant_ttc,
      },
      {
        'headers': headers,
      }
    )
  }

  deletePaiement(token, paiement_pk) {
    const url = apiBaseUrl + `/paiement/` + paiement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  downloadPaiement (token, paiement_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-paiement/' + paiement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }

  getAllPaiementsForManager (token, startDate, endDate, client) {
    /*
    BE CAREFUL : client can be STRING as '' : c'est quand rien n'est sélectionné
    */
    const url = apiBaseUrl + `/paiements-manager/` + startDate + '/' + endDate
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {}
    if (client != '') {
      params['client'] = client
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }


  // -------------- ONLINE PAIEMENTS -------------------
  getAllFactureOnlinePaiements (token, facture_pk) {
    const url = apiBaseUrl + `/get-all-online-paiements/` + facture_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addOnlinePaiement (token, facture_pk, montant, is_relance, relance_frequence) {
    const url = apiBaseUrl + '/create-online-paiement'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'facture': facture_pk,
        'montant': montant,
        'is_relance': is_relance,
        'relance_frequence': relance_frequence,
      },
      {
        'headers': headers,
      }
    )
  }


  deleteOnlinePaiement(token, online_paiement_pk) {
    const url = apiBaseUrl + `/online-paiement/` + online_paiement_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  getAllOnlinePaiementsForManager (token, startDate, endDate, client) {
    /*
    BE CAREFUL : client can be STRING as '' : c'est quand rien n'est sélectionné
    */
    const url = apiBaseUrl + `/online-paiements-manager/` + startDate + '/' + endDate
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {}
    if (client != '') {
      params['client'] = client
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }


  // -------------- DEVIS -------------------
  addDevis (token, client_pk, exercice_comptable, title, facturation_address,
        date, message) {
    const url = apiBaseUrl + '/create-devis'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.post(
      url,
      {
        'client': client_pk,
        'exercice_comptable': exercice_comptable,
        'title': title,
        'facturation_address': facturation_address,
        'date': date,
        'message': message,
      },
      {
        'headers': headers,
      }
    )
  }

  getDevis(token, pk) {
    const url = apiBaseUrl + `/devis/` + pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  modifyDevis(token, devis_pk, title, facturation_address,
        date, message) {

    const url = apiBaseUrl + `/devis/` + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'title': title,
        'facturation_address': facturation_address,
        'date': date,
        'message': message,
      },
      {
        'headers': headers,
      }
    )
  }
  deleteDevis(token, devis_pk) {
    const url = apiBaseUrl + `/devis/` + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }

  sendDevisToClient (token, devis_pk, sending_email, customized_message, answer_email) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/send-devis-to-client/' + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      sending_email: sending_email,
      customized_message: customized_message,
      answer_email: answer_email
    }
    return axios_instance.put(
      url, data,
      {
        'headers': headers,
      },
    )
  }

  markDevisAsSent(token, devis_pk) {
    const url = apiBaseUrl + `/mark-devis-as-sent/` + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url, {},
      {
        'headers': headers,
      }
    )
  }


  getAllDevisForManager (token, startDate, endDate, client) {
    /*
    BE CAREFUL : client can be STRING as '' : c'est quand rien n'est sélectionné
    */
    const url = apiBaseUrl + `/devis-manager/` + startDate + '/' + endDate
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {}
    if (client != '') {
      params['client'] = client
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }


  downloadDevis (token, devis_pk) {
    //https://thewebtier.com/snippets/download-files-with-axios/
    const url = apiBaseUrl + '/download-devis/' + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(
      url,
      {
        'headers': headers,
        'responseType': 'blob', // important
      },
    )
  }


  // -------------- UNTES DEVIS --------------

  getAllDevisUnitesDevis (token, devis_pk) {
    const url = apiBaseUrl + `/get-all-unite-devis/` + devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers,})
  }

  addUniteDevis (token, devis_pk, date, description, quantite,
        prix_unitaire, remise_ht, tva) {
    const url = apiBaseUrl + '/create-unite-devis'
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'devis': devis_pk,
      'date': date,
      'description': description,
      'quantite': quantite,
      'prix_unitaire': prix_unitaire,
      'tva': tva
    }

    if (remise_ht == '') {
      data['remise_ht'] = 0
    }
    else {
      data['remise_ht'] = remise_ht
    }
    return axios_instance.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  modifyUniteDevis (token, unite_devis_pk, date, description, quantite,
        prix_unitaire, remise_ht, tva) {
    const url = apiBaseUrl + `/unite-devis/` + unite_devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {
        'date': date,
        'description': description,
        'quantite': quantite,
        'prix_unitaire': prix_unitaire,
        'remise_ht': remise_ht,
        'tva': tva,
      },
      {
        'headers': headers,
      }
    )
  }

  deleteUniteDevis (token, unite_devis_pk) {
    const url = apiBaseUrl + `/unite-devis/` + unite_devis_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.delete(
      url,
      {
        'headers': headers,
      }
    )
  }



  // -------------- TASKS -------------------
  getAllTasksForManager (token, startDate, endDate, client, mission, collaborateur) {
    /*
    BE CAREFUL : client and collaborateur are '' and mission is 'false' : c'est à cause du CSelect et du autocomplete ...
    */
    const url = apiBaseUrl + `/tasks-manager/` + startDate + '/' + endDate
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var params = {}
    if (client != '') {
      params['client'] = client
    }
    if (mission != 'false') {
      params['mission'] = mission
    }
    if (collaborateur != 'false') {
      params['collaborateur'] = collaborateur
    }
    return axios_instance.get(url, {'headers': headers, params: params})
  }

  getAllTasksForUniteFactureGeneration (token, startDate, endDate, client, mission, is_not_billed) {
    /*
      startDate and endDate : 'YYYY-MM-DD'
      client and mission : int
      is_not_billed : 'false' or 'true'
    */
    const url = apiBaseUrl + `/get-tasks-for-unites-facture/` + startDate + '/' + endDate + '/' + client + '/' + mission + '/' + is_not_billed
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.get(url, {'headers': headers})
  }

  markTasksAsBilled(token, tasks) {
    const url = apiBaseUrl + `/mark-tasks-as-billed`
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'tasks': tasks
    }
    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  setCollaborateurManagerAlert(token, collaborateur_pk, manager_alert) {
    const url = apiBaseUrl + `/manager-set-collaborateur-alert/` + collaborateur_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    var data = {
      'manager_alert': manager_alert,
    }
    return axios_instance.put(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }

  removeCollaborateurManagerAlert(token, collaborateur_pk) {
    const url = apiBaseUrl + `/manager-remove-collaborateur-alert/` + collaborateur_pk
    var headers = {
      'Authorization': 'Bearer '+ JSON.parse(token).access,
      'Content-Type': 'application/json',
    }
    return axios_instance.put(
      url,
      {},
      {
        'headers': headers,
      }
    )
  }

}
