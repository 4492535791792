<template>
  <div class="ManagerAllOnlinePaiements">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CToaster :autohide="2000">
      <template v-for="toast in copiedToasts">
        <CToast
          :key="'toast' + toast"
          :show="true"
        >
        <template #default>
          <strong class="text-success">Copié 👍</strong>
        </template>
        </CToast>
      </template>
    </CToaster>


    <CRow>
      <CCol>
        <h1> Paiements en ligne </h1>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol xl="5">
            <CInput
              label="Début de date de facturation"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="startDateFilter">
            </CInput>
          </CCol>
          <CCol xl="5">
            <CInput
              label="Fin de date de facturation"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="endDateFilter">
            </CInput>
          </CCol>
          <CCol xl="2">
            <CButton
              color="primary"
              variant="outline"
              shape="pill"
              block
              @click="getAllOnlinePaiementsForManager">
            Filtrer par date
          </CButton>
          </CCol>
        </CRow>

        <CRow class="mt-3 mt-xl-0">
          <CCol>
            <a class="eclerk-link" href="#" @click="reinitializeWeek">
              Depuis le début de la semaine
            </a>
            <br class="d-sm-block d-md-none">

            <a class="ml-0 ml-md-2 eclerk-link" href="#" @click="reinitializeMonth">
              Depuis le début du mois
            </a>
          </CCol>
        </CRow>

        <CRow class="mt-3">

          <CCol sm="4">
            <CInputCheckbox
              label="Tous les clients"
              :checked.sync="isAllClientsFilter"
              :custom="true"
            >
            </CInputCheckbox>
          </CCol>
          <CCol v-if="!isAllClientsFilter" sm="8" class="mt-2 mt-sm-0">
            <vue-bootstrap-typeahead
              v-model="clientFilterName"
              :data="filterClients"
              :serializer="item => item.name"
              @hit="clientFilterObject = $event"
               placeholder="Chercher votre client par nom"
            />

          </CCol>
        </CRow>

        <CRow v-if="companyStripeConnectedAccountId == '' || !companyStripeConnectedAccountChargesEnabled || companyStripeConnectedAccountChargesEnabled == 'false'" class="mt-4">
          <CCol class="text-center" style="font-size: 1.5em;">
            ⛔ Pour créer un lien de paiement en ligne, il est nécessaire de <router-link to="/">valider le compte bancaire de votre entreprise</router-link> ⛔
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <div v-if="companyStripeConnectedAccountId != '' && companyStripeConnectedAccountChargesEnabled && companyStripeConnectedAccountChargesEnabled == 'true'">
      <CRow style="font-size: 1.2rem;">
        <CCol>
          <strong class="text-primary" style="font-size: 1.6rem;">{{nbOnlinePaiementsAPayer}}</strong> paiements en ligne à payer dont <strong class="text-danger" style="font-size: 1.6rem;">{{nbOnlinePaiementsEnRetard}}</strong> en retard
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol xl="4">
          <CWidgetIcon
            text="montant total"
            color="primary"
            :icon-padding="false"
          >
            <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotal).toFixed(0))) + ' €'}}</strong>
          </CWidgetIcon>
        </CCol>
        <CCol xl="4">
          <CWidgetIcon
            text="payé"
            color="success"
            :icon-padding="false"
          >
            <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalPaid).toFixed(0))) + ' €'}}</strong>
          </CWidgetIcon>
        </CCol>
        <CCol xl="4">
          <CWidgetIcon
            text="reste à payer"
            color="danger"
            :icon-padding="false"
          >
            <strong>{{String(formatThousandSeparatorNumber(parseFloat(montantTotalToPay).toFixed(0))) + ' €'}}</strong>
          </CWidgetIcon>
        </CCol>
      </CRow>


      <CRow>
        <CCol>
          <CCard >
            <CCardBody>
              <CRow>
                <CCol>
                  <CDataTable
                    class="cursor-table"
                    :items-per-page="50"
                    sorter
                    pagination
                    :items="onlinePaiementsRender"
                    :fields="onlinePaiementsRenderFields"
                    @row-clicked="goToFacturePage"
                    :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                  >
                  <template #over-table>
                    <exportTableDataComponent
                      v-if="onlinePaiementsRender.length > 0"
                      v-bind:data="onlinePaiementsRender"
                      v-bind:fields="onlinePaiementsFileRenderFields"
                      name="Eclerk - Paiements en ligne"
                      label="Exporter la liste"
                    />
                  </template>
                  <template #client="{item}">
                    <td>
                     <nobr>{{item.client}}</nobr>
                    </td>
                  </template>
                  <template #date_echeance="{item}">
                    <td class="text-center">
                     <nobr><span>{{$dayjs(item.date_echeance).format('DD/MM/YYYY')}}</span></nobr>
                    </td>
                  </template>
                  <template #montant="{item}">
                    <td class="text-center">
                      <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant).toFixed(2))}} €</nobr>
                    </td>
                  </template>

                  <template #facture_montant_total_ttc="{item}">
                    <td class="text-center">
                      <nobr>{{formatThousandSeparatorNumber(parseFloat(item.facture_montant_total_ttc).toFixed(2))}} €</nobr>
                    </td>
                  </template>

                  <template #is_paid="{item}">
                    <td class="text-center">
                      <span v-if="item.is_paid">Oui <br> <small>{{$dayjs(item.paiement_date).format('DD/MM/YYYY')}}</small> </span>
                      <span v-else>Non</span>
                    </td>
                  </template>

                  <template #actions="{item}">
                    <div @click.stop>
                      <td>
                        <CButtonGroup>
                          <CButton
                            class="ml-2"
                            color="outline-dark"
                            size="sm"
                            @click="copyCodeToClipBoard(vueBaseUrl + '/pages/paiement-en-ligne/' + item.random_url)">
                            <CIcon name="cil-copy" /> Lien de paiement
                          </CButton>
                        </CButtonGroup>
                      </td>
                    </div>
                  </template>

                  <template #no-items-view>
                    <CRow>
                      <CCol class="text-center">
                        Aucun paiement en ligne créé
                      </CCol>
                    </CRow>
                  </template>
                  </CDataTable>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>

import { vueBaseUrl } from '@/variables/localVariables'

import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';

import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ManagerAllOnlinePaiements',
  components: {
    Loading,
    VueBootstrapTypeahead,
    exportTableDataComponent
  },
  mixins: [
    renderMixins,
    userMixins
  ],
  data: function () {
    var today = this.$dayjs()
    var oneMonthAgo = today.subtract('3', 'months')
    return {

      vueBaseUrl: vueBaseUrl,
      copiedToasts: 0,

      // ------------- ONLINE PAIEMENTS -----------
      startDateFilter: oneMonthAgo.format('YYYY-MM-DD'),
      endDateFilter: today.format('YYYY-MM-DD'),

      isAllClientsFilter: true,
      filterClients: [],
      clientFilterName: '',
      clientFilterObject: {
        id: '',
        name: ''
      },

      onlinePaiements: [],
      onlinePaiementsRender: [],
      onlinePaiementsRenderFields: [
        { key: "facture_number", label: "N° de la facture"},
        { key: "client", label: "Client"},
        { key: "intitule", label: "Intitulé"},
        { key: "date_echeance", label: "Date d'échéance de la facture", sortable: true },
        { key: "montant", label: "Montant à payer", sortable: true },
        { key: "facture_montant_total_ttc", label: "Montant total TTC de la facture", sortable: true },
        { key: "is_paid", label: "Payé ?", sortable: true },
        { key: "actions", label: "", _style: "min-width: 200px;"},
      ],
      onlinePaiementsFileRenderFields: {
        'Client': 'client',
        'Mission': 'mission',
        'Libellé': 'intitule',
        'Date de facturation': 'date_facturation',
        "Date d'échéance de la facture": "date_echeance",
        "Montant à payer": 'montant',
        "Montant total TTC de la facture": 'montant_total_ttc',
        "A été payé ?": 'is_paid',
      },
      isAllManagerOnlinePaiementsDashboardLoading: false,

      nbOnlinePaiementsAPayer: 0,
      nbOnlinePaiementsEnRetard: 0,
      montantTotal: 0,
      montantTotalPaid: 0,
      montantTotalToPay: 0,

      // ------------- COMPANY -----------
      company: {
        company_name: '',
        siret: '',
        forme_sociale: '',
        capital: 0,
        address: '',
        phone_country_code: '',
        phone_number: '',
        email: '',
        iban: '',
        bic: '',
        titulaire: ''
      },
      isCompanyLoading: false,


    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllManagerOnlinePaiementsDashboardLoading) {
        return true
      }
      return false
    },
    companyStripeConnectedAccountId () {
      return this.$store.state.user.companyStripeConnectedAccountId
    },
    companyStripeConnectedAccountChargesEnabled () {
      return this.$store.state.user.companyStripeConnectedAccountChargesEnabled
    }
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllOnlinePaiementsForManager()
  },


  watch: {

    clientFilterName(newQuery) {
      if (newQuery == '') {
        this.filterClients = []
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.filterClients = res.data
        })
      }
    },

    clientFilterObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          this.getAllOnlinePaiementsForManager()
        }
      }
      else if (newObject.id == '') {
        this.getAllOnlinePaiementsForManager()
      }
    },

    isAllClientsFilter(newBoolean) {
      if (newBoolean) {
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
    },

    onlinePaiements: function (newOnlinePaiements) {

      // PAIEMENT STATISTIQUES
      this.nbOnlinePaiementsEnRetard = 0
      this.nbOnlinePaiementsAPayer = 0
      this.montantTotal = 0
      this.montantTotalPaid = 0
      this.montantTotalToPay = 0

      // PAIEMENT DETAILLED
      this.onlinePaiementsRender = []

      for (var i = 0; i < newOnlinePaiements.length; i++) {
        var temp_data = {
          'id': newOnlinePaiements[i].id,
          'facture_id': newOnlinePaiements[i].facture.id,
          'random_url': newOnlinePaiements[i].random_url,
          'facture_number': newOnlinePaiements[i].facture.facture_number,
          'client': newOnlinePaiements[i].facture.client_name,
          'mission': newOnlinePaiements[i].facture.mission_name,
          'intitule': newOnlinePaiements[i].facture.facture_title,
          'date_facturation': newOnlinePaiements[i].facture.date_facturation,
          'date_echeance': newOnlinePaiements[i].facture.date_echeance,
          'montant': newOnlinePaiements[i].montant,
          'facture_montant_total_ttc': newOnlinePaiements[i].facture.montant_total_ttc,
          'is_paid': newOnlinePaiements[i].is_paid

        }

        var today = this.$dayjs()
        var echeanceDate = this.$dayjs(newOnlinePaiements[i].facture.date_echeance)
        this.montantTotal = parseFloat(this.montantTotal) + parseFloat(newOnlinePaiements[i].montant)
        if (newOnlinePaiements[i].is_paid) {
          temp_data['_classes'] = 'table-info'
          this.montantTotalPaid = parseFloat(this.montantTotalPaid) + parseFloat(newOnlinePaiements[i].montant)
        }
        else if (today > echeanceDate) {
          temp_data['_classes'] = 'table-danger'
          this.nbOnlinePaiementsEnRetard ++
          this.nbOnlinePaiementsAPayer ++
          this.montantTotalToPay = parseFloat(this.montantTotalToPay) + parseFloat(newOnlinePaiements[i].montant)
        }
        else {
          this.nbOnlinePaiementsAPayer ++
          this.montantTotalToPay = parseFloat(this.montantTotalToPay) + parseFloat(newOnlinePaiements[i].montant)
        }
        this.onlinePaiementsRender.push(temp_data)
      }
    },
  },
  methods: {
    // -------------- GETTERS -----------------------
    getAllOnlinePaiementsForManager () {
      this.isAllManagerOnlinePaiementsDashboardLoading = true
      apiManagerConnected.getAllOnlinePaiementsForManager(this.token, this.startDateFilter, this.endDateFilter, this.clientFilterObject.id)
      .then((result) => {
        this.onlinePaiements = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerOnlinePaiementsDashboardLoading = false
      })
    },

    copyCodeToClipBoard (textToCopy) {
      const el = document.createElement('textarea');
      el.value = textToCopy;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copiedToasts ++
    },

    // ------------ Filter functions ----------------
    reinitializeWeek () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('week').format('YYYY-MM-DD')
      this.getAllOnlinePaiementsForManager()
    },
    reinitializeMonth () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('month').format('YYYY-MM-DD')
      this.getAllOnlinePaiementsForManager()
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToFacturePage(item) {
      var routeData = this.$router.resolve('modify-facture/' + item.facture_id);
      window.open(routeData.href, '_blank');
    }

  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}


</style>
